<template>
  <div class="passenger-item">
    <div class="form-item">
      <div class="label">{{$t('PassengersNumber',[itemIndex+1,travelerType])}}</div>
    </div>
    <!--稱謂-->
    <div class="form-item">
      <div class="label" :class="{'is-error': datas.isCalled.$error}">
        <span class="required">*</span>{{$t('Appellation')}}
      </div>
      <div>
        <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                   :class="{'is-error': datas.isCalled.$error}"
                   @blur="datas.isCalled.$touch()"
                   v-model="datas.isCalled.$model">
          <el-option v-for="item in appellationOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--姓名(英文)-->
    <div class="form-item">
      <div class="label" :class="{'is-error': datas.englishSurname.$error || datas.englishName.$error}">
        <span class="required">*</span>{{$t('Name')}}({{$t('English')}})
      </div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
               :class="{'is-error': datas.englishSurname.$error}"
               @blur="datas.englishSurname.$touch()"
               v-model.trim="datas.englishSurname.$model"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name"
               :class="{'is-error': datas.englishName.$error}"
               @blur="datas.englishName.$touch()"
               v-model.trim="datas.englishName.$model"/>
      </div>
    </div>
    <!--姓名(中文)-->
    <div class="form-item">
      <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
               v-model.trim="datas.chineseSurname.$model"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名"
               v-model.trim="datas.chineseName.$model"/>
      </div>
    </div>
    <!--性別-->
    <div class="form-item">
      <div class="label" :class="{'is-error': datas.genderType.$error}">
        <span class="required">*</span>{{$t('Gender')}}
      </div>
      <div>
        <radio v-model="datas.genderType.$model" label="M">{{$t('Male')}}</radio>
        <radio v-model="datas.genderType.$model" label="F">{{$t('Female')}}</radio>
      </div>
    </div>
    <div class="cl">
      <!--證件類型-->
      <div class="fl form-item m-r">
        <div class="label" :class="{'is-error': datas.paperworkType.$error}">
          <span class="required">*</span>{{$t('Certificate Type')}}
        </div>
        <div>
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                     :class="{'is-error': datas.paperworkType.$error}"
                     @blur="datas.paperworkType.$touch()"
                     v-model="datas.paperworkType.$model"
                     @change="paperworkTypeChange">
            <el-option v-for="item in idcardTypeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--其他證件-->
      <div class="fl form-item m-r" v-if="datas.paperworkType.$model==='qt'">
        <div class="label" :class="{'is-error': datas.otherPaperwork.$error}">
          <span class="required">*</span>{{$t('Other Certificate')}}
        </div>
        <div>
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 :class="{'is-error': datas.otherPaperwork.$error}"
                 @blur="datas.otherPaperwork.$touch()"
                 v-model.trim="datas.otherPaperwork.$model"/>
        </div>
      </div>
      <!--證件簽發地-->
      <div class="fl form-item">
        <div class="label" :class="{'is-error': datas.issuingCountry.$error}">
          <span class="required">*</span>{{$t('Certificate of Issue')}}
        </div>
        <div>
          <el-select class="select" size="small" filterable :placeholder="$t('PleaseChoose')"
                     :class="{'is-error': datas.issuingCountry.$error}"
                     @blur="datas.issuingCountry.$touch()"
                     v-model="datas.issuingCountry.$model"
                     :disabled="['CT3','CT6','CT7','CT5'].includes(datas.paperworkType.$model)">
            <el-option v-for="item in countryOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </div>
      </div>
      <i/>
    </div>
    <div class="cl">
      <!--證件號碼-->
      <div class="fl form-item m-r">
        <div class="label" :class="{'is-error': datas.idNumber.$error}">
          <span class="required">*</span>{{$t('Certificate Number')}}
        </div>
        <div>
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 :class="{'is-error': datas.idNumber.$error}"
                 @blur="datas.idNumber.$touch()"
                 v-model.trim="datas.idNumber.$model"/>
        </div>
      </div>
      <!--證件有效期-->
      <div class="fl form-item m-r" v-if="datas.paperworkType.$model!=='sfz'">
        <div class="label" :class="{'is-error': datas.effectiveDate.$error}">
          <span class="required">*</span>{{$t('Certificate Validity Period')}}
        </div>
        <div>
          <el-date-picker class="select"
                          type="date"
                          size="small"
                          :picker-options="effectiveDateOptions"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('PleaseChoose')"
                          :class="{'is-error': datas.effectiveDate.$error}"
                          @blur="datas.effectiveDate.$touch()"
                          v-model="datas.effectiveDate.$model">
          </el-date-picker>
        </div>
      </div>
      <!--出生日期-->
      <div class="form-item">
        <div class="label" :class="{'is-error': datas.birthday.$error}">
          <span class="required">*</span>{{$t('DateOfBirth')}}
        </div>
        <div>
          <el-date-picker class="select"
                          type="date"
                          size="small"
                          :picker-options="birthdayOptions"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('PleaseChoose')"
                          :class="{'is-error': datas.birthday.$error}"
                          @blur="datas.birthday.$touch()"
                          v-model="datas.birthday.$model">
          </el-date-picker>
        </div>
      </div>
      <i/>
    </div>
    <!--手提電話-->
    <div class="form-item" v-if="datas.travelerType.$model==='adult'">
      <div class="label">{{$t('Mobile Number')}}</div>
      <div class="cl">
        <el-select class="select m-r" size="small" :placeholder="$t('Area code')"
                   v-model="datas.phoneType.$model">
          <el-option v-for="item in areaCodeOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
               v-model.trim="datas.userPhone.$model"/>
      </div>
    </div>
    <!--    <div class="form-item">-->
    <!--      <div class="label"><span class="required">*</span>{{$t('Insurance')}}</div>-->
    <!--      <div>-->
    <!--        <radio :label="name" v-for="(value, name) of $t('Insurance Options')" :key="name"-->
    <!--               v-model="datas.insurance">{{value}}-->
    <!--        </radio>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <a class="btn-del" @click="handleDelete" v-if="passengerList.length>1"></a>-->
  </div>
</template>
<script>
  import Radio from '../../../../components/Radio';
  import {dicts} from '../../../../config';

  export default {
    name: 'AddOrderPassengerInfoItem',
    props: {
      appellationOptions: Array,
      countryOptions: Array,
      areaCodeOptions: Array,
      idcardTypeOptions: Array,
      birthdayOptions: Object,
      effectiveDateOptions: Object,
      passengerList: Array,
      datas: Object,
      itemIndex: Number
    },
    computed: {
      travelerType() {
        if (this.datas.$model && this.datas.$model.travelerType && this.datas.$model.isOccupyBed) {
          return dicts.travelerType[this.datas.$model.travelerType + this.datas.$model.isOccupyBed];
        }
        return '';
      }
    },
    data() {
      return {
        traveler: ''
      };
    },
    components: {Radio},
    methods: {
      // 切换证件类型后证件签发证相关操作
      paperworkTypeChange(value) {
        switch (value) {
          // 香港簽證身份書
          case 'CT3':
            this.datas.issuingCountry.$model = '020511';
            break;
          // 回鄉證(CT6)、台胞證(CTtw、CT20)、港澳通行証(CT7)
          case 'CT6':
          // case 'CTtw':
          // case 'CT20':
          // eslint-disable-next-line no-fallthrough
          case 'CT7':
            this.datas.issuingCountry.$model = '020509';
            break;
          // 澳門旅行證(CT5)
          case 'CT5':
            this.datas.issuingCountry.$model = '020512';
            break;
          // 身份证
          case 'sfz':
            this.datas.effectiveDate.$model = '';
            break;
          default:
            this.datas.issuingCountry.$model = '';
            break;
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .passenger-item{
    position:relative;padding:20px;margin-top:10px;background-color:#f4f4f4;border-radius:4px;
    &:first-child{margin-top:0;}
    .m-r{margin-right:8px;}
    //.btn-del{position:absolute;top:20px;right:20px;width:20px;height:20px;background:url(../../../assets/images/icon/close-circle.png) no-repeat 0 0;cursor:pointer;}
  }
  .form-item{
    margin-bottom:24px;
    &:last-child{margin-bottom:0;}
    .label{
      margin-bottom:8px;
      .tips{margin-top:4px;color:#999;}
    }
    .ipt{
      width:170px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:170px;vertical-align:top;}
    .required{color:#f00;}
    .btn-clear{display:inline-block;margin-left:20px;line-height:32px;vertical-align:top;color:#ff6f61;cursor:pointer;}
    /deep/ .radio .label{color:#321908;}
  }
</style>
