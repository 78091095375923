<template>
  <div v-if="$pv">
    <!--稱謂-->
    <div class="form-item">
      <div class="label" :class="{'is-error': $pv.contactInformation.isCalled.$error}">
        <span class="required">*</span>{{$t('Appellation')}}
      </div>
      <div>
        <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                   :class="{'is-error': $pv.contactInformation.isCalled.$error}"
                   @blur="$pv.contactInformation.isCalled.$touch()"
                   v-model="datas.isCalled">
          <el-option v-for="item in appellationOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--姓名(英文)-->
    <div class="form-item">
      <div class="label"
           :class="{'is-error': $pv.contactInformation.englishSurname.$error || $pv.contactInformation.englishName.$error}">
        <span class="required">*</span>{{$t('Name')}}({{$t('English')}})
      </div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
               :class="{'is-error': $pv.contactInformation.englishSurname.$error}"
               @blur="$pv.contactInformation.englishSurname.$touch()"
               v-model.trim="datas.englishSurname"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name"
               :class="{'is-error': $pv.contactInformation.englishName.$error}"
               @blur="$pv.contactInformation.englishName.$touch()"
               v-model.trim="datas.englishName"/>
      </div>
    </div>
    <!--姓名(中文)-->
    <div class="form-item">
      <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
               v-model.trim="datas.chineseSurname"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名"
               v-model.trim="datas.chineseName"/>
      </div>
    </div>
    <!--手提電話-->
    <div class="form-item">
      <div class="label"
           :class="{'is-error': $pv.contactInformation.phoneType.$error || $pv.contactInformation.userPhone.$error}">
        <span class="required">*</span>{{$t('Mobile Number')}}
      </div>
      <div class="cl">
        <el-select class="select m-r" size="small" :placeholder="$t('Area code')"
                   :class="{'is-error': $pv.contactInformation.phoneType.$error}"
                   @blur="$pv.contactInformation.phoneType.$touch()"
                   v-model="datas.phoneType">
          <el-option v-for="item in areaCodeOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
               :class="{'is-error': $pv.contactInformation.userPhone.$error}"
               @blur="$pv.contactInformation.userPhone.$touch()"
               v-model.trim="datas.userPhone"/>
      </div>
    </div>
    <!--Email-->
    <div class="form-item">
      <div class="label" :class="{'is-error': $pv.contactInformation.userEmail.$error}">
        <span class="required">*</span>{{$t('Email')}}
      </div>
      <div>
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
               :class="{'is-error': $pv.contactInformation.userEmail.$error}"
               @blur="$pv.contactInformation.userEmail.$touch()"
               v-model.trim="datas.userEmail"/>
      </div>
    </div>
    <!--緊急聯絡人（建議非出行人士）-->
    <div id="emergencyContact" class="form-item emergency-contact">
      <div class="label">
        <checkbox v-model="emergencyContact.isUrgent">
          <div class="checkbox-label b">{{$t('Emergency Contact')[1]}}</div>
        </checkbox>
      </div>
    </div>
    <template v-if="emergencyContact.isUrgent">
      <!--姓名(英文)-->
      <div class="form-item">
        <div class="label" :class="{'is-error': $pv.emergencyContact.englishSurname.$error || $pv.emergencyContact.englishName.$error}">
          <span class="required">*</span>{{$t('Name')}}({{$t('English')}})
        </div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
                 :class="{'is-error': $pv.emergencyContact.englishSurname.$error}"
                 @blur="$pv.emergencyContact.englishSurname.$touch()"
                 v-model.trim="emergencyContact.englishSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name"
                 :class="{'is-error': $pv.emergencyContact.englishName.$error}"
                 @blur="$pv.emergencyContact.englishName.$touch()"
                 v-model.trim="emergencyContact.englishName"/>
        </div>
      </div>
      <!--姓名(中文)-->
      <div class="form-item">
        <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
                 v-model.trim="emergencyContact.chineseSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名"
                 v-model.trim="emergencyContact.chineseName"/>
        </div>
      </div>
      <!--手提電話-->
      <div class="form-item">
        <div class="label" :class="{'is-error': $pv.emergencyContact.phoneType.$error || $pv.emergencyContact.userPhone.$error}">
          <span class="required">*</span>{{$t('Mobile Number')}}
        </div>
        <div class="cl">
          <el-select class="select m-r" size="small" :placeholder="$t('Area code')"
                     :class="{'is-error': $pv.emergencyContact.phoneType.$error}"
                     @blur="$pv.emergencyContact.phoneType.$touch()"
                     v-model="emergencyContact.phoneType">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
                 :class="{'is-error': $pv.emergencyContact.userPhone.$error}"
                 @blur="$pv.emergencyContact.userPhone.$touch()"
                 v-model.trim="emergencyContact.userPhone"/>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Checkbox from '../../../../components/Checkbox';

  export default {
    name: 'AddOrderContactInformation',
    components: {Checkbox},
    props: {
      areaCodeOptions: Array,
      appellationOptions: Array,
      datas: Object,
      emergencyContact: Object
    },
    computed: {
      $pv() {
        return this.$parent.$v || this.$parent.$parent.$v;
      }
    }
  };
</script>
<style scoped lang="less">
  .form-item{
    margin-bottom:24px;
    &:last-child{margin-bottom:0;}
    .label{
      margin-bottom:8px;
      .tips{margin-top:4px;color:#999;}
    }
    .ipt{
      width:170px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:170px;}
    .m-r{margin-right:8px;}
    .required{color:#f00;}
  }
  .emergency-contact{padding-top:24px;border-top:1px solid #ccc;}
</style>
